import React from 'react';
import currencyToSymbolMap from 'currency-symbol-map/map';
import { Money } from '@wix/ambassador-pricing-plans-read-api/types';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { VIEWER_HOOKS } from '../../../../../constants';
import { classes as commonClasses } from '../../../../../styles/common.st.css';
import { st, classes } from './Price.st.css';

interface Props {
  price?: Money;
  highlighted: boolean;
}

export const Price: React.FC<Props> = ({ price, highlighted }) => {
  const { isMobile } = useEnvironment();

  return (
    <div data-hook={VIEWER_HOOKS.PLAN_PRICE} className={st(classes.wrapper, { highlighted, mobile: isMobile })}>
      <span aria-hidden="true" data-hook={VIEWER_HOOKS.PRICE_CURRENCY} className={classes.currency}>
        {currencyToSymbol(price?.currency)}
      </span>
      <span aria-hidden="true" data-hook={VIEWER_HOOKS.PRICE_AMOUNT} className={classes.price}>
        {formatPrice(price)}
      </span>
      <span data-hook={VIEWER_HOOKS.PLAN_PRICE_SCREEN_READER} className={commonClasses.srOnly}>
        {formatPrice(price) + currencyToSymbol(price?.currency)}
      </span>
    </div>
  );
};

function formatPrice(price?: Money) {
  const [amount, fraction] = price?.amount?.toString().split('.') ?? [];
  if (fraction && fraction.length) {
    return amount + '.' + fraction + (fraction.length > 1 ? '' : '0');
  }
  return amount;
}

export function currencyToSymbol(code?: string): string {
  if (typeof code === 'string') {
    return currencyToSymbolMap[code.toUpperCase()] || code;
  }
  return '';
}
